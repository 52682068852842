<template>
  <div
    class="languageSelector dnl-text-blue-600 dnl-border-2 dnl-border-current dnl-border-solid dnl-rounded-lg dnl-flex dnl-items-center dnl-relative"
  >
    <label
      hidden
      for="languageSelector"
    >
      Language:
    </label>
    <svg class="dnl-w-6 dnl-m-x-2 dnl-h-6 dnl-absolute dnl-left-0 dnl-pointer-events-none">
      <use href="/img/icons/language.svg#language" />
    </svg>
    <select
      id="languageSelector"
      v-model="activeLanguage"
      class="dnl-py-1 dnl-pe-4 dnl-ps-10 dnl-w-full dnl-flex-1 dnl-text-inherit"
      aria-label="Select a Language"
    >
      <option
        v-for="language in languages"
        :key="language.shortCode"
        :value="language.tag"
      >
        {{ language.shortCode }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
  import { watch, ref } from 'vue';
  import {
    getLanguages,
    useLanguageStore,
    setLanguage,
    setLanguageInUserSettings,
  } from '@/services/language.service';
  import { getAuthToken } from '@/services/auth';

  const languages = ref(getLanguages());
  const activeLanguage = ref(useLanguageStore.language);

  watch(activeLanguage, async value => {
    if (value) {
      setLanguage(value);

      const token = await getAuthToken();
      if (token) {
        await setLanguageInUserSettings(value);
      }
    }
  });
</script>

<style scoped>
  .languageSelector::after {
    position: relative;
    content: '';
    background-image: url('/img/icons/arrow-down.svg');
    background-repeat: no-repeat;
    right: 0.5em;
    top: -0.2em;
    width: 1em;
    height: 1em;
    pointer-events: none;
    fill: var(--neutral-400);
  }

  .languageSelector > select:focus {
    outline: none;
  }
</style>
