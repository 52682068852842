// const colors = {
//   'apple-500': 'rgba(76, 175, 80, 1)', // #4CAF50
//   'azure-radiance-600': 'rgba(34, 120, 254, 1)', // #2278FE
//   black: 'rgba(0, 0, 0, 1)', // #000000
//   'black-10': 'rgba(0, 0, 0, 0.1)', // #000000
//   'black-20': 'rgba(0, 0, 0, 0.2)', // #000000
//   'black-30': 'rgba(0, 0, 0, 0.3)', // #000000
//   'black-40': 'rgba(0, 0, 0, 0.4)', // #000000
//   'blocked-light-purple': 'rgba(167, 125, 244, 1)', // #A77DF4
//   'blue-70': 'rgba(221, 233, 246, 1)', // #DDE9F6
//   'blue-80': 'rgba(22, 31, 57, 1)', // #161F39
//   'blue-90': 'rgba(54, 120, 204, 1)', // #3678CC
//   'cod-gray': 'rgba(29, 29, 29, 0.1)', // #1D1D1D
//   'cool-gray': 'rgba(176, 176, 176, 1)', // #B0B0B0
//   'coral-red-400': 'rgba(255, 82, 82, 1)', // #FF5252
//   'd-darken-1': 'rgba(67, 160, 71, 1)', // #43A047
//   'd-gray-10': 'rgba(241, 243, 248, 1)', // #F1F3F8
//   'd-gray-20': 'rgba(250, 251, 252, 1)', // #FAFBFC
//   'd-gray-30': 'rgba(227, 227, 227, 1)', // #E3E3E3
//   'd-gray-40': 'rgba(250, 251, 252, 1)', // #FAFBFC
//   'd-gray-50': 'rgba(234, 234, 234, 1)', // #EAEAEA
//   'd-gray-500': 'rgba(107, 114, 128, 1)', // #6B7280
//   'd-gray-501': 'rgba(124, 124, 124, 1)', // #7C7C7C
//   'd-gray-502': 'rgba(209, 213, 219, 1)', // #D1D5DB
//   'd-gray-503': 'rgba(252, 251, 255, 1)', // #FCFBFF
//   'd-gray-504': 'rgba(249, 252, 250, 1)', // #F9FCFA
//   'd-gray-90': 'rgba(62, 62, 62, 1)', // #3E3E3E
//   'dark-gray': 'rgba(109, 136, 149, 1)', // #6D8895
//   'dark-gray-medium': 'rgba(55, 63, 65, 1)', // #373F41
//   'electric-violet-600': 'rgba(128, 67, 243, 1)', // #8043F3
//   'extra-dark-blue': 'rgba(55, 63, 65, 1)', // #373F41
//   'fern-500': 'rgba(103, 171, 91, 1)', // #67AB5B
//   gallery: 'rgba(238, 238, 238, 1)', // #EEEEEE
//   'gothic-400': 'rgba(108, 136, 149, 1)', // #6C8895
//   'gray-60': 'rgba(0, 0, 0, 0.6)', // #000000
//   'gray-70': 'rgba(109, 136, 149, 1)', // #6D8895
//   grey: 'rgba(55, 63, 65, 1)', // #373F41
//   'grey-25': 'rgba(0, 0, 0, 0.25)', // #000000
//   'grey-30': 'rgba(94, 94, 94, 1)', // #5E5E5E
//   'grey-40': 'rgba(0, 0, 0, 0.38)', // #000000
//   'grey-50': 'rgba(92, 92, 92, 1)', // #5C5C5C
//   'grey-70': 'rgba(70, 70, 70, 1)', // #464646
//   'grey-80': 'rgba(55, 63, 65, 0.8)', // #373F41
//   'grey-90': 'rgba(97, 97, 97, 1)', // #616161
//   kimberly: 'rgba(102, 112, 133, 1)', // #667085
//   'light-gray': 'rgba(208, 213, 221, 1)', // #D0D5DD
//   mercury: 'rgba(232, 232, 232, 1)', // #E8E8E8
//   'navy-blue': 'rgba(0, 113, 206, 1)', // #0071CE
//   nero: 'rgba(29, 29, 29, 1)', // #1D1D1D
//   'pattens-blue-100': 'rgba(225, 245, 254, 1)', // #E1F5FE
//   platinum: 'rgba(151, 151, 151, 1)', // #979797
//   'primary-grey': 'rgba(66, 66, 66, 1)', // #424242
//   'proposal-orange': 'rgba(255, 153, 0, 1)', // #FF9900
//   'reopened-orange': 'rgba(255, 165, 0, 1)', // #FFA500
//   'royal-blue': 'rgba(25, 118, 210, 1)', // #1976D2
//   silver: 'rgba(116, 116, 116, 1)', // #747474
//   'slate-gray': 'rgba(108, 136, 149, 1)', // #6C8895
//   'steel-blue': 'rgba(109, 136, 149, 1)', // #6D8895
//   'success-green': 'rgba(112, 191, 115, 1)', // #70BF73
//   white: 'rgba(255, 255, 255, 1)', // #FFFFFF
//   'white-smoke': 'rgba(242, 242, 242, 1)', // #F2F2F2
//   'wild-sand-50': 'rgba(245, 245, 245, 1)', // #F5F5F5
// } as const;

// Wrong colors:
// light-gray => originally neutral-400 => neutral-200
// d-gray-30 => originally neutral-600 => neutral-200
// d-gray-500 => originally neutral-600 => zink-500
// d-gray-502 => originally zink-400 => neutral-200
// d-gray-501 => originally neutral-900 => zink-500
// black-20 => originally neutral-900 => neutral-200
// black-40 => originally neutral-900 => neutral-400

// missing colors:
// 'azure-radiance-600': 'rgba(34, 120, 254, 1)', => blue-700
// 'blue-70': 'rgba(221, 233, 246, 1)', => blue-100
// 'blue-90': 'rgba(54, 120, 204, 1)', => blue-600
// 'coral-red-400': 'rgba(255, 82, 82, 1)', => red-500
// 'd-gray-50': 'rgba(234, 234, 234, 1)', => neutral-200
// 'd-gray-504': 'rgba(249, 252, 250, 1)', => green-50
// 'gray-60': 'rgba(0, 0, 0, 0.6)', => neutral-600
// grey: 'rgba(55, 63, 65, 1)', => neutral-700
// 'grey-25': 'rgba(0, 0, 0, 0.25)' => neutral-300
// 'grey-40': 'rgba(0, 0, 0, 0.38)', => neutral-400
// 'grey-50': 'rgba(92, 92, 92, 1)', => neutral-600
// 'grey-70': 'rgba(70, 70, 70, 1)', => neutral-700
// 'pattens-blue-100': 'rgba(225, 245, 254, 1)', => blue-100
// 'primary-grey': 'rgba(66, 66, 66, 1)', => neutral-700
// silver: 'rgba(116, 116, 116, 1)', => zink-500
// 'steel-blue': 'rgba(109, 136, 149, 1)', => jeans-indigo
// 'success-green': 'rgba(112, 191, 115, 1)', => green-500
// 'white-smoke': 'rgba(242, 242, 242, 1)', => slate-50
// Added blue-900 => #1e3a8a => rgba(30, 58, 138, 1)
// Added blue-400 => #60a5fa => rgba(96, 165, 250, 1)
// Added red-400 => #f87171 => rgba(248, 113, 113, 1)
// Added purple-400 => #c084fc => rgba(192, 132, 252, 1)
// Added yellow-200 => #fef08a => rgba(254, 240, 138, 1)

const colors = {
  'neutral-900': 'rgba(23, 23, 23, 1)', // #171717
  'neutral-700': 'rgba(64, 64, 64, 1)', // #404040
  'neutral-600': 'rgba(82, 82, 82, 1)', // #525252
  'neutral-400': 'rgba(163, 163, 163, 1)', // #a3a3a3
  'neutral-300': 'rgba(212, 212, 212, 1)', // #d4d4d4
  'neutral-200': 'rgba(229, 229, 229, 1)', // #e5e5e5
  'slate-500': 'rgba(100, 116, 139, 1)', // #64748b
  'slate-300': 'rgba(203, 213, 225, 1)', // #cbd5e1
  'slate-200': 'rgba(226, 232, 240, 1)', // #e2e8f0
  'slate-100': 'rgba(241, 245, 249, 1)', // #f1f5f9
  'slate-50': 'rgba(248, 250, 252, 1)', // #f8fafc
  'blue-900': 'rgba(30, 58, 138, 1)', // #1e3a8a
  'blue-700': 'rgba(29, 78, 216, 1)', // #1d4ed8
  'blue-600': 'rgba(37, 99, 235, 1)', // #2563eb
  'blue-500': 'rgba(59, 130, 246, 1)', // #3b82f6
  'blue-400': 'rgba(96, 165, 250, 1)', // #60a5fa
  'blue-300': 'rgba(147, 197, 253, 1)', // #93c5fd
  'blue-200': 'rgba(191, 219, 254, 1)', // #bfdbfe
  'blue-100': 'rgba(219, 234, 254, 1)', // #dbeafe
  'zink-500': 'rgba(113, 113, 122, 1)', // #71717a
  'zink-400': 'rgba(161, 161, 170, 1)', // #a1a1aa
  'purple-700': 'rgba(126, 34, 206, 1)', // #7e22ce
  'purple-500': 'rgba(168, 85, 247, 1)', // #a855f7
  'purple-400': 'rgba(192, 132, 252, 1)', // #c084fc
  'purple-300': 'rgba(216, 180, 254, 1)', // #d8b4fe
  'purple-100': 'rgb(237, 233, 254)', // #EDE9FE,
  'purple-50': 'rgba(252, 251, 255, 1)', // #fcfbff
  'green-600': 'rgba(22, 163, 74, 1)', // #16a34a
  'green-500': 'rgba(34, 197, 94, 1)', // #22c55e
  'green-400': 'rgba(74, 222, 128, 1)', // #4ade80
  'green-300': 'rgba(134, 239, 172, 1)', // #86efac
  'green-200': 'rgba(187, 247, 208, 1)', // #bbf7d0
  'green-50': 'rgba(240, 253, 244, 1)', // #f0fdf4
  'yellow-500': 'rgba(234, 179, 8, 1)', // #eab308
  'yellow-400': 'rgba(250, 204, 21, 1)', // #facc15
  'yellow-300': 'rgba(253, 224, 71, 1)', // #fde047
  'yellow-200': 'rgba(254, 240, 138, 1)', // #fef08a
  'red-500': 'rgba(239, 68, 68, 1)', // #ef4444
  'red-400': 'rgba(248, 113, 113, 1)', // #f87171
  'red-300': 'rgba(252, 165, 165, 1)', // #fca5a5
  'red-200': 'rgba(254, 202, 202, 1)', // #fecaca
  'cyan-600': 'rgba(8, 145, 178, 1)', // #0891b2
  'cyan-300': 'rgba(103, 232, 249, 1)', // #67e8f9
  'jeans-indigo': 'rgba(109, 136, 149, 1)', // #6d8895
  'jeans-indigo-light': 'rgba(163, 199, 217, 1)', // #a3c7d9
  'unactive-grey': 'rgba(197, 207, 213, 1)', // #c5cfd5
  'active-grey': 'rgba(109, 136, 149, 1)', // #6d8895
  'jeans-light': 'rgb(192, 209, 230)', // #C0D1E6
  white: 'rgba(255, 255, 255, 1)', // #ffffff
  black: 'rgba(0, 0, 0, 1)', // #000000
} as const;

export default colors;

export function RGBAToHexA(rgba: string, forceRemoveAlpha = true) {
  return (
    '#' +
    rgba
      .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .filter((_, index) => !forceRemoveAlpha || index !== 3)
      .map(string => parseFloat(string)) // Converts them to numbers
      .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
      .map(number => number.toString(16)) // Converts numbers to hex
      .map(string => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
      .join('')
  ); // Puts the array to togehter to a string
}
