<template>
  <div
    class="dnl-flex dnl-gap-4 dnl-p-x-4 dnl-p-y-3 dnl-items-center dnl-justify-between dnl-sticky dnl-top-0 dnl-z-50 dnl-bg-white dnl-shadow-md dnl-shadow-gray-400"
  >
    <router-link
      title="Home"
      :to="user ? '/' : '/login'"
      data-testId="home"
    >
      <img
        v-if="user && user.userGroup"
        :src="logoUrl"
        :alt="user.userGroup"
        class="dnl-mr-2 dnl-h-8"
      />
      <img
        v-else
        src="@/assets/icon-br-m.png"
        alt="DNL logo"
        class="dnl-mr-2 dnl-h-8"
      />
    </router-link>

    <BreadCrumbs
      v-if="breadcrumbs.length > 0"
      :breadcrumbs="breadcrumbs"
      :hasHomeIcon="false"
    />

    <div class="dnl-flex dnl-items-center dnl-gap-2">
      <LanguageSelector />
      <AccountMenu
        v-if="user"
        @logout="logout"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, onBeforeUnmount, watch } from 'vue';
  import { useRoute } from '@/utils/router';
  import AccountMenu from '@/components/Shared/AppBar/AppBarAccountMenu.vue';
  import BreadCrumbs from '@/components/Shared/BreadCrumbs.vue';
  import defaultLogo from '@/assets/icon-br-m.png';
  import LanguageSelector from '@/components/Shared/LanguageSelector.vue';
  import { breadcrumbsStore, setBreadcrumbs } from '@/services/breadcrumbs.service';
  import { user } from '@/store/modules/auth/auth-state';
  import { logout } from '@/store/modules/auth/auth-actions';

  const route = useRoute();

  const breadcrumbs = computed(() => breadcrumbsStore.breadcrumbs);
  const logoUrl = computed(() => user.value?.userGroupLogoURL || defaultLogo);

  watch(
    () => route.path,
    () => {
      setBreadcrumbs([]);
    },
  );

  /** fix for the bug when breadcrumbs are visible after relogin in the wrong place
   * TODO: reset the whole app state on logout
   */
  onBeforeUnmount(() => {
    setBreadcrumbs([]);
  });
</script>
