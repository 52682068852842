<template>
  <v-menu
    contentClass="c-menu c-menu--account"
    offsetY
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        aria-label="User Menu"
        data-cy="userMenuButton"
        v-on="on"
      >
        <svg class="dnl-w-7 dnl-h-7 dnl-text-neutral-500">
          <use href="/img/icons/icons.svg#mdi-account-circle"></use>
        </svg>
      </v-btn>
    </template>
    <v-card>
      <v-list dense>
        <v-list-item-group color="primary">
          <div
            v-for="link in menuLinks"
            :key="link.title"
          >
            <v-list-item
              v-if="link.action"
              :data-cy="link.cypress"
              @click="$emit(link.action)"
            >
              <v-list-item-icon class="my-0 align-self-center">
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-else-if="link.link"
              :data-cy="link.cypress"
              :href="$t(link.link)"
              :target="link.target"
              :rel="link.rel"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-else
              :to="link.appLink"
              :data-cy="link.cypress"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
  const menuLinks = [
    {
      icon: 'mdi-logout',
      title: 'user.action.logout',
      action: 'logout',
      cypress: 'logout',
    },
    {
      icon: 'mdi-new-box',
      title: 'user.link.releaseNotes.text',
      link: 'user.link.releaseNotes.url',
      target: '_blank',
      rel: 'noopener noreferrer',
      cypress: 'release-notes',
    },
    {
      icon: 'mdi-information-variant',
      title: 'generic.inprint.text',
      link: 'generic.inprint.url',
      target: '_blank',
      rel: 'noopener noreferrer',
      cypress: 'impressum',
    },
    {
      icon: 'mdi-shield-check-outline',
      title: 'user.link.privacy.text',
      appLink: '/privacy-policy',
      cypress: 'datenschutz',
    },
  ];
</script>
