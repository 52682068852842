<template>
  <div :class="className">
    <nav
      aria-label="Breadcrumb"
      class="c-breadcrumbs"
      data-testid="breadcrumbs"
    >
      <ul class="!dnl-p-0 dnl-list-none dnl-flex dnl-items-center dnl-font-bold">
        <li
          v-if="shouldShowHomeIcon"
          class="md:dnl-mr-6"
        >
          <router-link to="/">
            <svg class="dnl-w-5 dnl-h-5 dnl-fill-blue-600">
              <use href="/img/icons/home.svg#home"></use>
            </svg>
          </router-link>
        </li>
        <li
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="index"
          :class="index == breadcrumbs.length - 1 ? 'dnl-mr-0' : 'dnl-mr-2'"
        >
          <router-link
            v-if="breadcrumb?.to !== '/'"
            class="dnl-flex dnl-items-center dnl-no-underline"
            :class="
              index === breadcrumbs.length - 1
                ? 'dnl-mr-0 dnl-text-sm md:dnl-text-xl !dnl-text-black'
                : 'dnl-text-sm md:dnl-text-base !dnl-text-blue-600'
            "
            :to="breadcrumb?.to || ''"
            :disabled="breadcrumb?.disabled"
          >
            <span>{{ breadcrumb.text }}</span>
            <span class="dnl-px-1 md:dnl-px-4 dnl-flex dnl-items-center">
              <svg
                v-if="index !== breadcrumbs.length - 1"
                viewBox="0 0 24 24"
                class="dnl-w-5 dnl-h-5 dnl-fill-blue-600"
              >
                <use href="/img/icons/chevron_right.svg#chevron_right"></use>
              </svg>
            </span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { Breadcrumbs } from '@/interfaces/BreadCrumbs';

  const props = defineProps({
    breadcrumbs: {
      type: Array<Breadcrumbs>,
      required: true,
    },
    hasHomeIcon: { type: Boolean, default: true },
    className: {
      type: String,
      default: '',
    },
  });

  const shouldShowHomeIcon = computed(() => {
    return props.breadcrumbs.length >= 2 && props.hasHomeIcon;
  });
</script>
